@import url('https://use.typekit.net/dxp0kzh.css');
@import url('https://fonts.googleapis.com/css?family=Karla:400,700|Montserrat:800,300&display=swap');

// Blue Giant Theme styles
@import '../colors';
@import '../mixins';

.theme--BlueGiant {
  ::selection {
    color: white;
    background-color: $color-bluegiant-red;
  }
  .karla--font {
    font-family: 'Karla', serif !important;
  }
  .montserrat--font {
    font-family: 'Montserrat', Arial, sans-serif !important;
  }
  .futura--font {
    font-family: futura-pt-bold, sans-serif !important;
    font-style: normal;
  }
  .application {
    @extend .karla--font;
  }

  h1 {
    @extend .montserrat--font;

    font-size: 45px;
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 960px) {
      font-size: (45px * 0.85) !important;
    }

    @media (max-width: 414px) {
      font-size: (45px * 0.7) !important;
    }
  }
  h2 {
    @extend .futura--font;

    margin-bottom: 0.5em;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: 960px) {
      font-size: (36px * 0.85) !important;
    }

    @media (max-width: 414px) {
      font-size: (36px * 0.7) !important;
    }
  }
  h3 {
    @extend .montserrat--font;

    margin-bottom: 0.5em;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;

    @media (max-width: 960px) {
      font-size: (24px * 0.85) !important;
    }

    @media (max-width: 414px) {
      font-size: (24px * 0.7) !important;
    }
  }
  .header-1 {
    @extend .montserrat--font;

    font-size: 49px !important;
    font-weight: 800 !important;
  }

  .header-3 {
    @extend .montserrat--font;

    font-weight: 800 !important;
    line-height: 1.1 !important;

    @media (max-width: 960px) {
      font-size: 46px !important  ;
    }
  }
  .header-2 {
    @extend .montserrat--font;

    @include font-size(45px);

    font-weight: 800 !important;
    line-height: 1.1 !important;
  }

  .header-4 {
    font-family: 'Futura', Arial, sans-serif !important;
    font-size: 46px !important;
    letter-spacing: 0.05em !important;
  }
  .r-title {
    @extend .karla--font;

    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold !important;
  }

  .btn-base {
    @extend .karla--font;

    font-weight: bold !important;
  }
}

.blue-giant-narrative-section {
  .section-content .container {
    border: 10px solid $color-bluegiant-blue;
  }
  .section--inner-title {
    // TODO (ESS): this is to compensate for DailyBeet's
    // theme styles being the default stylus vars
    padding: 0;
    color: $color-bluegiant-blue;
    border: none;
  }
}
